<section class="parent_container w-full" id="benificiaries-partners-container">
  <div
    class="flex flex-col gap-10 py-20 md:py-8 lg:py-12 px-4 md:px-14 lg:px-16 items-start justify-start md:items-center md:justify-center ">
    <p class="text-purple_100 text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36" id="benificiaries-partners">
      Beneficiaries & Partners</p>

    <div class="block lg:hidden">
      <div class="grid grid-cols-2  gap-4 w-full">
        <div class="col-span-1">
          <div class="flex items-center justify-between rounded-md bg-blue_100 px-4 py-5">
            <p class="text-hurrey-16 text-white" id="school_benificiaries">School</p>
            <img src="../../../../assets/icons/school_icon.svg" alt="school_icon" class="icon">
          </div>

        </div>
        <div class="col-span-1 w-full">
          <div class="flex items-center justify-between rounded-md bg-rose_100 px-4 py-5  ">
            <p class="text-hurrey-16 text-white" id="funders_benificiaries">CSR
              Funders</p>
            <img src="../../../../assets/icons/csr_funders_icon.svg" alt="csr_funders_icon" class="icon">
          </div>
        </div>


        <div class="col-span-2 bg-amber_100 w-full">
          <div class="flex items-center justify-between  rounded-md px-4 py-5">
            <p class="text-hurrey-16 text-white" id="govt-administration">Government & Administration</p>
            <img src="../../../../assets/icons/govt_office_icon.svg" alt="government_&_Administration_icon"
              class="icon">
          </div>

        </div>

        <div class="col-span-1 w-full">
          <div class="flex items-center justify-between rounded-md bg-purple_100 px-4 py-5  ">
            <p class="text-hurrey-16 text-white" id="partner-teacher">Teachers</p>
            <img src="../../../../assets/icons/teachers_icon.svg" alt="teachers_icon" class="icon">
          </div>

        </div>
        <div class="col-span-1 w-full">
          <div class="flex items-center justify-between rounded-md bg-blue_100 px-4 py-5">
            <p class="text-hurrey-16 text-white" id="partner-parents">Parents</p>
            <img src="../../../../assets/icons/parents_icon.svg" alt="parents_icon" class="icon">
          </div>

        </div>
        <div class="col-span-2 w-full">
          <div class="flex items-center justify-between rounded-md bg-amber_100 px-4 py-5 ">
            <p class="text-hurrey-16 text-white" id="partner-ngo">Non Governmental Organization</p>
            <img src="../../../../assets/icons/non-govt_office_icon.svg" alt="non_governmental_organization"
              class="icon">
          </div>

        </div>
        <div class="col-span-1 w-full">
          <div class="flex items-center justify-between rounded-md bg-rose_100 px-4 py-5">
            <p class="text-hurrey-16 text-white" id="partner-students">Students</p>
            <img src="../../../../assets/icons/students_group_icon.svg" alt="students_group_icon" class="icon">
          </div>

        </div>
        <div class="col-span-1 w-full">
          <div class="flex items-center justify-between rounded-md bg-purple_100 px-4 py-5">
            <p class="text-hurrey-16 text-white" id="partner-smart_class">Smart Class Provider</p>
            <img src="../../../../assets/icons/smart_class_provider_icon.svg" alt="smart_class_provider_icon"
              class="icon">
          </div>
        </div>

        <div class="col-span-2 w-full">
          <div class="flex items-center justify-center gap-2 rounded-md bg-white py-5 learn_more_tab"  [routerLink]="[ '/use-cases' ]">
            <p class="text-purple_100 text-hurrey-24"> Learn more</p>
            <img src="../../../../assets/icons/purple_arrow_icon.svg" alt="purple_rotate_arrow_icon" width="12"
              height="12">
          </div>

        </div>

      </div>
    </div>




    <div class="hidden lg:block self-stretch">
      <div class="grid grid-cols-3 gap-4 auto-rows-[120px]">
        <ng-container *ngFor="let ben of beneficiaresContents;let i = index;let isLast=last">
          <div [routerLink]="[ '/use-cases' ]" class="flex items-center px-6 benificiary_card" [id]="'benificiary_card'+ ben.content" role="button"
            [class]="ben.class"
            [ngClass]="{'col-span-1': i==0||i==3||i==5,'col-span-2':i==1||i==2||i==4,'row-span-2':i==2||i==5,'row-end-4':i==2,'row-start-3':i==5,'justify-center':i!=0&&i!=1}"
            (mouseenter)="ben.hovered=true" (mouseleave)="ben.hovered=false">
            <div *ngIf="!isLast" class="subdiv" [id]="'benificiary_card_wrap'+ ben.content">
              <p class="text-hurrey-24" [id]="ben.content">{{ben.content}}</p>
              <img *ngIf="i==0 || i==1 || i==4 " [ngClass]="{'hidden': !ben.hovered,'block':ben.hovered}"
                [@slideInRight]="ben.hovered?'visible':'void'" [src]="ben.image" [alt]="ben.content" [height]="100"
                [width]="100">
              <img *ngIf="i!= 3 && !(i==0 || i==1|| i==4)" [ngClass]="{'hidden': !ben.hovered,'block':ben.hovered}"
                [@slideInDown]="ben.hovered?'visible':'void'" [src]="ben.image" [alt]="ben.content" [height]="100"
                [width]="100">

              <img *ngIf="i==3 && !ben.hovered" [src]="ben.image" [alt]="ben.content" [height]="32" [width]="32">
              <img *ngIf="i==3 && ben.hovered" [src]="ben.hoverImage" [alt]="ben.content" [height]="32" [width]="32">
            </div>
            <div *ngIf="isLast" class="flex flex-col gap-4">
              <ng-container *ngFor="let subContent of returnSubContent(ben);">
                <p class="subContent text-center" [ngClass]="subContent.bgColor"
                  [id]="'benificiary_partners'+subContent.text">
                  {{subContent.text}}</p>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
