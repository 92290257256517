<section class="bg-amber_100">
  <div class="py-14 md:py-10 lg:py-12 px-4 md:px-24">
    <div class="flex flex-col gap-11">
      <div class="flex items-start justify-start md:items-center md:justify-center">
        <p class="text-white text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36" id="mission_vision_header">Our Mission,
          Our Vision</p>
      </div>
      <div class="hidden md:block">
        <div [@slideInDown] class="flex flex-col rounded-xl bg-white w-full">
          <div class="flex items-center justify-center">
            <div class="tab tab_1 " [ngClass]="{ 'active': selectedTab === 'tab1' }" (click)="selectTab('tab1')"
              id="atomo-for-start-ups">Atomo
              for Startups
            </div>
            <div class="tab tab_2" [ngClass]="{ 'active': selectedTab === 'tab2' }" (click)="selectTab('tab2')"
              id="nation-building">Nation
              Building
            </div>
            <div class=" tab tab_3" [ngClass]="{ 'active': selectedTab === 'tab3' }" (click)="selectTab('tab3')"
              id="green-school">Green
              School
            </div>
          </div>

          <div *ngFor="let item of details; let i = index">
            <div *ngIf="selectedTab === 'tab' + (i + 1)" [id]="item.title">
              <div class="grid grid-cols-12 pt-10  md:gap-10  lg:gap-20">
                <div class="col-span-4 ">
                  <div class="flex items-center justify-center">
                    <p [id]="'mission_'+i" class="text-[124px] lg:text-[200px] text-blue_100"
                      [ngClass]="{'text-blue_100': item.number === '1', 'text-rose_100': item.number === '2', 'text-[#128807]': item.number === '3'}">
                      {{item.number}}</p>
                  </div>
                </div>
                <div class="col-span-8  pb-5 pr-4 lg:pr-16 ">
                  <div class="flex flex-col gap-5 mb-3 ">
                    <div class="flex ">
                      <p class="text-hurrey-12 text-blue_100 line" [id]="item.title+'_'+'mission'"
                        [ngClass]="{'text-blue_100': item.number === '1', 'text-rose_100': item.number === '2', 'text-[#128807]': item.number === '3', 'line-blue': item.number === '1', 'line-rose': item.number === '2', 'line-green': item.number === '3'}">
                        {{item.mission}} </p>
                    </div>
                    <p class="text-hurrey-16 lg:text-hurrey-28 text-black" [id]="item.title+'_'+'mission_about'">
                      {{item.about}}</p>
                    <p class="text-hurrey-12 lg:text-hurrey-16 text-grey" [id]="item.title+'_'+'mission_decription'">
                      {{item.description}} </p>
                  </div>
                  <!-- <div class="flex gap-2 items-center justify-end py-3 pr-4 check_eligibility"
                    routerLink="/check-eligiblity" *ngIf="i === 0">
                    <p role="button" id="check_eligibility">Check Eligibility</p>
                    <img src="../../../../assets/icons/arrow-down-left.svg" alt="left_arrow_button" width="24"
                      height="24" class="icon">
                  </div> -->
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- This is only for mobile sreen  -->
      <div class="block md:hidden">
        <div class="scroll_container">
          <div *ngFor="let item of details; let i=index">
            <div class="mobile_card rounded-xl bg-white">
              <div class="flex flex-col justify-between pt-12 px-4 h-full">
                <div class="flex flex-col gap-6">
                  <p [id]="item.title+'_'+'mission'" class="text-hurrey-12 "
                    [ngClass]="{'text-purple_100': i === 0, 'text-rose_100': i === 1, 'text-[#128807]': i === 2}">
                    {{item.mission}}</p>
                  <p class="text-hurrey-24 text-black text-left " [id]="item.title+'_'+'mission_about'">{{item.about}}
                  </p>
                  <p class="text-hureey-16 text-grey text-left" [id]="item.title+'_'+'mission_decription'">
                    {{item.description}}</p>
                  <!-- <div class="flex gap-2 items-center check_eligibility" routerLink="/check-eligiblity" *ngIf="i === 0">
                    <p class="" role="button" id="check_eligibility_mobile"> Check Eligibility</p>
                    <img src="../../../../assets/icons/arrow-down-left.svg" alt="left icon" width="20" height="20"
                      class="icon">
                  </div> -->
                </div>

                <div class="flex items-end justify-end pr-4 ">
                  <p [id]="'mission_'+i" class="text-[200px]"
                    [ngClass]="{'text-purple_100': i === 0, 'text-rose_100': i === 1, 'text-[#128807]': i === 2}">
                    {{item.number}}
                  </p>
                </div>


              </div>




            </div>
          </div>
        </div>


      </div>


    </div>

  </div>

</section>
