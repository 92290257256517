import { inject, Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  CollectionReference,
  Firestore,
} from '@angular/fire/firestore';
import { ContactUs } from '../models/contact_us.model';
import { CampaignForm } from '../models/campaign_form.model';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  private firestore: Firestore = inject(Firestore);

  constructor() {}

  addDocToContactUs(contact_us_form:ContactUs) {
    const contactUsCollRef: CollectionReference = collection(
      this.firestore,
      `websites/atomo/contact_us`
    );

    return addDoc(contactUsCollRef,contact_us_form);
  }


  addCampaignForm(campaign_form:CampaignForm){
    const campaignFormCollRef: CollectionReference = collection(
      this.firestore,
      `websites/atomo/campaign_form`
    );

    return addDoc(campaignFormCollRef,campaign_form);
  }
}
