<div class="container">
  <atomos-header></atomos-header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <atomos-footer></atomos-footer>

  <!-- <p>Atoms are unstable right now. We will be back soon.</p>

  <img src="../../../assets/images/atom_gif.gif" alt="Stomo under contruction"> -->


</div>
