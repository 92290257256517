import { Component } from '@angular/core';
import { CampaignHeaderComponent } from '../campaign-header/campaign-header.component';
import { CampaignUspComponent } from '../campaign-usp/campaign-usp.component';
import { CampaignFormComponent } from '../campaign-form/campaign-form.component';

@Component({
  selector: 'app-campaign-landing',
  standalone: true,
  imports: [CampaignHeaderComponent,CampaignUspComponent,CampaignFormComponent],
  templateUrl: './campaign-landing.component.html',
  styleUrl: './campaign-landing.component.scss'
})
export class CampaignLandingComponent {

}
