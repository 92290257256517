<section id="campaign_header" class="h-screen campaign_head px-4 md:px-14 lg:px-[108px] flex items-center relative">
  <div class="back_drop"></div>
  <ng-container>
    <div class="flex flex-col gap-10 z-10" [@fadeInLeftOnEnter] >
      <div class="flex flex-col gap-4 text-white">
        <p id="head_text" class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48">Meet Atomo! Education, But Cooler
        </p>
        <p class="text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-24" id="sub_head_text">Welcome to the Cool Revolution in Education</p>
      </div>
    </div>
  </ng-container>








  <!-- <div class="flex gap-2 items-center nav_container">
    <i-tabler name="arrow-left" id="arrow-left" class="text-white" [ngClass]="{'opacity-50': selectedIndex==0}"
      (click)="navigateBetweenSlides(-1)"></i-tabler>
    <i-tabler name="arrow-right" id="arrow-right" class="text-white" [ngClass]="{'opacity-50': selectedIndex==3}"
      (click)="navigateBetweenSlides(1)"></i-tabler>
  </div> -->




</section>






<!-- <ng-container *ngIf="selectedIndex == 1">
    <div class="flex flex-col gap-10" [@fadeInLeftOnEnter] id="usp-outer-wrap">
      <div class="flex flex-col gap-4 text-white" id="usp-inner-wrap">
        <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48" id="usp_title">Education That Lasts Forever</p>
        <p class="text-hurrey-16" id="usp_description">Education is unbreakable, and Atomo makes it last—strong,
          reliable, and built for success.</p>
      </div>
    </div>
  </ng-container>



  <ng-container *ngIf="selectedIndex == 2">
    <div class="flex flex-col gap-10" [@fadeInLeftOnEnter] id="usp-outer-wrap">
      <div class="flex flex-col gap-4 text-white" id="usp-inner-wrap">
        <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48" id="usp_title">Individualized Learning, Powered by
          AI</p>
        <p class="text-hurrey-16" id="usp_description">Atomo adapts to every student, teacher, and subject—tailored
          lessons and instant feedback.
        </p>
      </div>
    </div>
  </ng-container>





  <ng-container *ngIf="selectedIndex == 3">
    <div class="flex flex-col gap-10" [@fadeInLeftOnEnter] id="usp-outer-wrap">
      <div class="flex flex-col gap-4 text-white" id="usp-inner-wrap">
        <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48" id="usp_title">Connect, Collaborate, Succeed</p>
        <p class="text-hurrey-16" id="usp_description">Atomo connects students to collaborate and achieve goals, all in
          one simple platform.</p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedIndex == 4">
    <div class="flex flex-col gap-10" [@fadeInLeftOnEnter] id="usp-outer-wrap">
      <div class="flex flex-col gap-4 text-white" id="usp-inner-wrap">
        <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48" id="usp_title">Education Without Boundaries</p>
        <p class="text-hurrey-16" id="usp_description">Break barriers with Atomo—bringing quality education to everyone,
          anywhere, anytime.</p>
      </div>
    </div>
  </ng-container> -->
