<section class="flex flex-col gap-4">
  <h3 class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 ">Exclusive Reseller Advantages</h3>

  <div class="grid grid-cols-12 gap-3">
    <div class="col-span-6 md:col-span-4 flex flex-col gap-3 lg:col-span-3 medium_box_shadow p-3 rounded-sm">
      <figure class="flex justify-center items-center">
        <img src="../../../../assets/icons/benifit.svg" alt="premium benifits">
      </figure>
      <h4 class="text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-24 text-center">Exclusive Benefits</h4>
      <p class="text-hurrey-12 md:text-hurrey-[14px] lg:text-hurrey-16 text-sky_dark">Offer the best-in-class AI-powered
        LMS for enhanced educational outcomes.</p>
    </div>

    <div class="col-span-6 md:col-span-4 flex flex-col gap-3 lg:col-span-3 medium_box_shadow p-3 rounded-sm">
      <figure class="flex justify-center items-center">
        <img src="../../../../assets/icons/marketing_support.svg" alt="marketing-support">
      </figure>
      <h4 class="text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-24 text-center">Marketing Support</h4>
      <p class="text-hurrey-12 md:text-hurrey-[14px] lg:text-hurrey-16 text-sky_dark">Leverage expert marketing
        materials and strategies to promote products effectively.</p>
    </div>


    <div class="col-span-6 md:col-span-4 flex flex-col gap-3 lg:col-span-3 medium_box_shadow p-3 rounded-sm">
      <figure class="flex justify-center items-center">
        <img src="../../../../assets/icons/discounts.svg" alt="exclusive-discounts">
      </figure>
      <h4 class="text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-24 text-center">Exclusive Discounts</h4>
      <p class="text-hurrey-12 md:text-hurrey-[14px] lg:text-hurrey-16 text-sky_dark">Boost your profits with
        reseller-only discounts on our advanced solutions.</p>
    </div>


    <div class="col-span-6 md:col-span-4 flex flex-col gap-3 lg:col-span-3 medium_box_shadow p-3 rounded-sm">
      <figure class="flex justify-center items-center">
        <img src="../../../../assets/icons/networking_opportunity.svg" alt="networking-opportunites">
      </figure>
      <h4 class="text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-24 text-center">Networking Opportunities</h4>
      <p class="text-hurrey-12 md:text-hurrey-[14px] lg:text-hurrey-16 text-sky_dark">Connect with industry leaders and
        grow within a thriving reseller community.</p>
    </div>


    <div class="col-span-6 md:col-span-4 flex flex-col gap-3 lg:col-span-3 medium_box_shadow p-3 rounded-sm">
      <figure class="flex justify-center items-center">
        <img src="../../../../assets/icons/continous_innovation.svg" alt="continous-innovation">
      </figure>
      <h4 class="text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-24 text-center">Continuous Innovation</h4>
      <p class="text-hurrey-12 md:text-hurrey-[14px] lg:text-hurrey-16 text-sky_dark">Stay ahead with AI-powered updates
        and cutting-edge learning technology.</p>
    </div>
  </div>
</section>




