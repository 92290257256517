<section id="usecases_main_container"
  class="h-screen slide px-4 md:px-14 lg:px-[108px] flex items-center relative bg-purple_100">
  <div class="flex flex-col gap-10" [@fadeInLeftOnEnter]>
    <div class="flex flex-col gap-4 text-white">
      <h1 class="text-hurrey-28 lg:text-hurrey-48">Transform Education with AI-Powered Solutions</h1>
      <p class="text-white text-hurrey-16 break-words md:w-[70%]">Join the Atomo's Reseller Program to bring cutting-edge AI-powered
        learning solutions to education. Empower institutions, grow your business, and revolutionize learning with our
        support and exclusive benefits.</p>
      <a role="button" href="https://forms.gle/Qxo6QUKRNsZMA4Tm8" target="_blank" class="explore_button" id="reseller-join-button">Join as Reseller</a>
    </div>
  </div>
</section>

<section id="why-partner-with-us" class="px-4 md:px-14 lg:px-[108px] py-4 md:py-5">
  <why-partner-with-us></why-partner-with-us>
</section>

<section id="reseller-advantages" class="px-4 md:px-14 lg:px-[108px] py-4 md:py-5">
  <reseller-advantages></reseller-advantages>
</section>

<section id="become-a-reseller" class="px-4 md:px-14 lg:px-[108px] py-4 md:py-5">
  <become-a-reseller></become-a-reseller>
</section>
