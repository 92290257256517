<section class="our_team_container" id="our_team_container">
  <div class="flex flex-col pt-24 pb-32 md:pt-14 md:pb-36 lg:pb-44 lg:pt-28   pl-4 md:pl-14 lg:pl-[108px] gap-6">

    <p id="team_heading" class="text-white text-hurrey-28 lg:text-hurrey-36"> Our Team, Our <br class="block md:hidden">
      Creative Minds</p>
    <div class="hidden lg:block" id="team_description">
      <p class="text-white text-hurrey-16"> Our team is a diverse blend of visionaries, innovators, and experts from
        various<br class="hidden lg:block"> disciplines, united by a common goal
        to redefine the landscape of AI and <br class="hidden lg:block"> education. With backgrounds ranging from
        computer
        science and engineering <br class="hidden lg:block"> to
        psychology and education, we bring a rich tapestry of perspectives to the <br class="hidden lg:block"> table.
        Each
        member of our team is driven
        by a
        shared passion for creating <br class="hidden lg:block"> impactful solutions that empower learners and educators
        alike. Collaborative <br class="hidden lg:block"> and
        forward-thinking, we thrive on challenges and are committed to pushing the <br class="hidden lg:block">
        boundaries
        of what's possible.
        Together, we
        harness the power of technology <br class="hidden md:block"> and human ingenuity to shape a brighter future for
        education and beyond.</p>
    </div>

    <div class="block lg:hidden">
      <p class="text-white text-hurrey-16"> Our diverse team of visionaries and experts <br class="block md:hidden"> is
        united by a <br class="hidden md:block "> passion for redefining AI and <br class="block md:hidden">
        education. With backgrounds <br class="hidden md:block ">
        spanning various <br class="block md:hidden"> disciplines, we bring rich perspectives to <br class="block ">
        empower
        learners and educators. Collaborative <br class="block md:hidden"> and
        forward- <br class="hidden md:block "> thinking, we push boundaries to <br class="block md:hidden">
        shape a brighter future.</p>
    </div>

  </div>
</section>

<!-- <section class=" pl-1 md:pl-4 mt-[-10%] md:mt-[-5%]" id="our_team_container">
  <div class="block">
    <div class="horizontal_scroll cursor-grab">
      <div class="flex gap-4 lg:gap-8">
        <div class="team-card absolute" *ngFor="let team of team_profile">
          <div class="background-image" [id]="'image_'+team.name" [style.backgroundImage]="'url(' + team.img + ')'">
          </div>
          <div class="member-info flex flex-col gap-2 ">
            <p class="text-white text-hurrey-16" [id]="team.name">{{team.name}}</p>
            <p class="text-white text-hurrey-12" [id]="team.name+'description'">{{team.designation}}</p>
          </div>
        </div>
      </div>
    </div>

  </div>

</section> -->
