import { Component } from '@angular/core';
import { aiPoweredEducationalV2 } from '../../../shared/models/ai-powered-educational.model';
import { TablerIconsModule } from 'angular-tabler-icons';
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  expandOnEnterAnimation,
  collapseOnLeaveAnimation,
} from 'angular-animations';

@Component({
  selector: 'ai-powered-education-v2',
  standalone: true,
  imports: [TablerIconsModule, NgFor, NgIf, NgClass],
  templateUrl: './ai-powered-education-v2.component.html',
  styleUrl: './ai-powered-education-v2.component.scss',
  animations: [
    expandOnEnterAnimation({ duration: 400 }),
    collapseOnLeaveAnimation({ duration: 400 }),
  ],
})
export class AiPoweredEducationV2Component {
  aiEductionalContentCards: aiPoweredEducationalV2[] = [
    {
      title: 'Easy To Connect',
      description:
        'The Atomo App combines numerous features into one cohesive ecosystem, delivering a streamlined and convenient digital experience.',
      isExpanded: false,
    },
    {
      title: 'loT Enabled Learning',
      description:
        'Embrace the future with IoT-enabled devices enhanced by sensory AI, guaranteeing seamless connectivity. Eliminate disruptions with systems meticulously designed for zero downtime.',
      isExpanded: false,
    },
    {
      title: 'Single Unified Ecosystem',
      description:
        'The Atomo App seamlessly integrates education into a single ecosystem, streamlining tasks and enriching the overall learning experience.',
      isExpanded: false,
    },
    {
      title: 'Cast Without Internet',
      description:
        'Experience the power of our technology with real-time content casting, independent of internet connectivity. Our solutions are designed for low power consumption and minimal environmental impact.',
      isExpanded: false,
    },
    {
      title: 'Low Power Utilization',
      description:
        'Our ecosystem, from connectivity to learning, is designed for optimal efficiency, reliability, and sustainability, contributing to a greener, more connected world.',
      isExpanded: false,
    },
    {
      title: 'Un-Interrupted Learning',
      description:
        'Foster uninterrupted learning with our cutting-edge solutions. Whether in a physical classroom or a virtual environment, our technology eliminates disruptions, enabling users to concentrate fully on acquiring knowledge.',
      isExpanded: false,
    },
  ];

  onCardClick(index: number) {
    this.aiEductionalContentCards.forEach((a, i) => {
      if (i != index) {
        a.isExpanded = false;
      }
    });
    this.aiEductionalContentCards[index].isExpanded =
      !this.aiEductionalContentCards[index].isExpanded;
  }
}
