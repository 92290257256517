<section class="our-partner-container w-full">
  <div
    class="flex flex-col justify-center items-center px-4 md:px-11 lg:px-24 gap-7  md:gap-12 pt-[120px] md:pt-14 pb-8 ">
    <div class="flex  items-start justify-start md:justify-center md:items-center">
      <p class="text-white text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36" id="our_partner_foundaries"> Our Partner
        Foundries</p>
    </div>
    <div [@slideInOut] class="grid grid-cols-12 md:grid-cols-8 gap-2 md:gap-4 lg:gap-6">
      <!-- <div class="col-span-4 md:col-span-2 flex justify-center items-center">
        <img src="../../../../assets/images/aye_learning_app.svg" alt="aye_learning_app">
      </div> -->
      <div class="col-span-6 md:col-span-2 flex justify-center items-center">
        <img src="../../../../assets/images/am_ns_India.svg" alt="AM/Ns_India">
      </div>

      <div class="col-span-6 md:col-span-2 flex justify-center items-center">
        <img src="../../../../assets/images/Hp_lmg.svg" alt="HP_logo">
      </div>

      <div class="col-span-6 md:col-span-2 flex justify-center items-center">
        <img src="../../../../assets/images/aspirational_district.svg" alt="Aspirational_district_logo">
      </div>
      <div class="col-span-6 md:col-span-2 flex justify-center items-center">
        <img src="../../../../assets/images/Ncert_logo.svg" alt="Ncert_logo" width="90">
      </div>

    </div>
    <!-- <button class="text-hurrey-16 text-black join_us" routerLink="/join-us" id="join_us">Join us</button> -->
  </div>
</section>
