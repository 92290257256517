import { DestroyRef, Directive, effect, ElementRef, inject, Input, signal } from '@angular/core';
import { merge, fromEvent, tap, filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numbersOnly]',
  standalone: true
})
export class NumbersOnlyDirective {
  private readonly el = inject(ElementRef<HTMLInputElement>);
  private readonly destroyRef = inject(DestroyRef);
  private readonly ngControl = inject(NgControl, { optional: true });
  @Input() set maxInputLength(value: number) {
    this._maxLength = value > 0 ? value : 10;
  }
  private _maxLength = 10;

  constructor() {
    this.setupEventListeners();
  }

  private getCurrentValue(): string {
    // Get value from control if available, otherwise from element
    return (this.ngControl?.value ?? this.el.nativeElement.value ?? '').toString();
  }

  private setupEventListeners(): void {
    // Handle keypress
    fromEvent<KeyboardEvent>(this.el.nativeElement, 'keypress')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => {
        const currentValue = this.getCurrentValue();

        // Prevent input if max length reached and not a special key
        if (currentValue.length >= this._maxLength && !this.isSpecialKey(event.key)) {
          event.preventDefault();
          return;
        }

        // Handle selection case
        const selectionStart = this.el.nativeElement.selectionStart ?? currentValue.length;
        const selectionEnd = this.el.nativeElement.selectionEnd ?? currentValue.length;
        const selectedLength = selectionEnd - selectionStart;

        // If not replacing selected text and at max length, prevent input
        if (selectedLength === 0 && currentValue.length >= this._maxLength && !this.isSpecialKey(event.key)) {
          event.preventDefault();
          return;
        }

        // Prevent non-digit input
        if (!this.isValidInput(event)) {
          event.preventDefault();
        }
      });

    // Handle paste
    fromEvent<ClipboardEvent>(this.el.nativeElement, 'paste')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => {
        event.preventDefault();
        const pastedText = event.clipboardData?.getData('text/plain') ?? '';
        const currentValue = this.getCurrentValue();
        const selectionStart = this.el.nativeElement.selectionStart ?? currentValue.length;
        const selectionEnd = this.el.nativeElement.selectionEnd ?? currentValue.length;

        const beforeSelection = currentValue.substring(0, selectionStart);
        const afterSelection = currentValue.substring(selectionEnd);
        const newValue = beforeSelection + pastedText + afterSelection;

        const sanitizedText = this.sanitizeInput(newValue);
        this.updateValue(sanitizedText);
      });

    // Handle input
    fromEvent<InputEvent>(this.el.nativeElement, 'input')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const currentValue = this.getCurrentValue();
        const sanitizedValue = this.sanitizeInput(currentValue);

        if (currentValue !== sanitizedValue) {
          this.updateValue(sanitizedValue);
        }
      });
  }

  private isValidInput(event: KeyboardEvent): boolean {
    if (this.isSpecialKey(event.key)) {
      return true;
    }

    const currentValue = this.getCurrentValue();
    const selectionStart = this.el.nativeElement.selectionStart ?? currentValue.length;
    const selectionEnd = this.el.nativeElement.selectionEnd ?? currentValue.length;
    const selectedLength = selectionEnd - selectionStart;
    const finalLength = currentValue.length - selectedLength + 1;

    return this.isDigit(event.key) && finalLength <= this._maxLength;
  }

  private isSpecialKey(key: string): boolean {
    const specialKeys = [
      'Tab',
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Home',
      'End',
      'Enter'
    ];
    return specialKeys.includes(key);
  }

  private isDigit(key: string): boolean {
    return /^\d$/.test(key);
  }

  private sanitizeInput(input: string): string {
    if (!input) return '';

    return input
      .replace(/[^\d]/g, '')
      .slice(0, this._maxLength);
  }

  private updateValue(value: string): void {
    if (this.getCurrentValue() !== value) {
      // Update the input element value
      this.el.nativeElement.value = value;

      // Dispatch input event
      const inputEvent = new InputEvent('input', {
        bubbles: true,
        cancelable: true,
        composed: true
      });
      this.el.nativeElement.dispatchEvent(inputEvent);

      // Update form control if available
      if (this.ngControl?.control) {
        this.ngControl.control.setValue(value, { emitEvent: true });
      }
    }
  }

}
