<div class="flex flex-col py-14 px-4 md:px-28 lg:px-72 ">
  <div class="flex flex-col items-start justify-start md:items-center md:justify-center ">
    <p class="text-black font-bold text-hurrey-20 lg:text-hurrey-24 mb-4 md:mb-5"> Get in touch</p>
    <p class="mb-10"> <span class="text-grey text-hurrey-16 lg:text-hurrey-20">Fill the details below or mail us
        at</span>
      <span class="text-hurrey-16 lg:text-hurrey-20 text-purple_100"> info&#64;theatomo.com </span>
    </p>
  </div>


  <p class="text-hurrey-16 font-bold text-black mb-2">Which role describes you best</p>
  <div class="grid grid-cols-2 gap-4 lg:gap-6 mb-4 lg:mb-6">
    <div class="col-span-2 md:col-span-1 gap-4 checkbox_container" *ngFor="let role of roles">
      <input type="radio" [id]="role.role" [name]="role.name" [value]="role.role" class="checkbox-input"
        [checked]="selected_role == role.role" (change)="handleCheckboxChange(role)" [(ngModel)]="selected_role">
      <p class="text-hurrey-16 text-grey">{{role.name}}</p>
    </div>

    <div class=" col-span-2 md:col-span-1">
      <atomo-input *ngIf="selected_role == 'others'" [required]="true" [placeholder]="'Specify your Role'"
        [formControl]="othersFormControl" label="Role" [type]="'text'" [validationState]="getOthersValidationState()"
        [validationMessage]="getOthersErrorMessage()"></atomo-input>
    </div>


  </div>

  <div class=" grid grid-cols-2 gap-4 lg:gap-6 mb-4 lg:mb-6" [formGroup]="getInTouchForm">
    <div class="col-span-2 flex flex-col gap-2">
      <atomo-input formControlName="name" [required]="true" [placeholder]="'Enter your Organization name'" label="Name"
        [type]="'text'" [validationState]="getValidationState('name')"
        [validationMessage]="getErrorMessage('name','Organization Name')"></atomo-input>
    </div>

    <div class="col-span-2 md:col-span-1 flex flex-col gap-2">
      <atomo-input formControlName="email" type="email" [required]="true" [placeholder]="'Enter your mail address'"
        label="Email" [validationState]="getValidationState('email')"
        [validationMessage]="getErrorMessage('email','Email ID')"></atomo-input>
    </div>

    <div class="col-span-2 md:col-span-1 flex flex-col gap-2">
      <atomo-input numbersOnly [maxInputLength]="10" formControlName="phone_number" type="tel" [required]="true"
        [placeholder]="'Enter 10 digit mobile number'" label="Phone Number"
        [validationState]="getValidationState('phone_number')" [validationMessage]="getErrorMessage('phone_number','Phone Number')" prefix="+91"></atomo-input>
    </div>

  </div>


  <p class="text-black font-bold text-hurrey-16 mb-2"> How can we help?</p>
  <textarea id="additionalComments" name="additionalComments" class="textarea mb-10"
    placeholder="Tell us a what you need from us" [(ngModel)]="description"></textarea>

  <div class="grid grid-cols-4 gap-4 md:gap-10">
    <div class="col-span-4 md:col-span-3">
      <p class="text-grey text-hurrey-16"> Verify all information is correct before clicking submit.</p>
    </div>
    <div class="col-span-4 md:col-span-1 flex items-center justify-center md:items-end md:justify-end">
      <button class="text-hurrey-16 text-white submit_button" (click)="onSubmit()" [disabled]="submitDisabledCheck">
        <span *ngIf="!addingDoc">Submit</span>
        <img *ngIf="addingDoc" src="../../../../assets/icons/spinner.svg" alt="loading" height="20" width="20">
      </button>
    </div>
  </div>


</div>
