import { NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  AtomoInputComponent,
  ValidationState,
} from '../../../shared/reusable-components/atomo-input/atomo-input.component';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NumbersOnlyDirective } from '../../../shared/directives/numbers-only.directive';
import { BackendService } from '../../../shared/services/backend.service';
import { CampaignForm } from '../../../shared/models/campaign_form.model';
import { Router } from '@angular/router';

@Component({
  selector: 'campaign-form',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    AtomoInputComponent,
    ReactiveFormsModule,
    NumbersOnlyDirective,
    FormsModule,
  ],
  templateUrl: './campaign-form.component.html',
  styleUrl: './campaign-form.component.scss',
})
export class CampaignFormComponent {
  private backendSrvc_: BackendService = inject(BackendService);
  private router:Router = inject(Router);
  addingDoc: boolean = false;

  selected_role: string = '';

  campaignForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
    age: new FormControl('', [Validators.required]),
    school_name: new FormControl('', [Validators.required]),
    school_city: new FormControl('', [Validators.required]),
    fav_teacher: new FormControl('', Validators.required),
    classroom_memory: new FormControl(''),
  });

  othersFormControl: FormControl = new FormControl(
    { value: '', disabled: false },
    [Validators.required]
  );

  roles: { role: string; name: string }[] = [
    { role: 'admin', name: 'Administrator' },
    { role: 'teacher', name: 'Teacher' },
    { role: 'student', name: 'Student' },
    { role: 'parent', name: 'Parent' },
    { role: 'others', name: 'Other' },
  ];

  handleCheckboxChange(selectedRole: { role: string; name: string }): void {
    this.selected_role = selectedRole.role;

    if (this.selected_role === 'others') {
      this.othersFormControl.enable();
    } else {
      this.othersFormControl.reset();
      this.othersFormControl.disable();
    }
  }

  getOthersValidationState(): ValidationState {
    if (this.othersFormControl.touched) {
      return this.othersFormControl.errors ? 'error' : 'success';
    }
    return null;
  }

  getOthersErrorMessage(): string {
    if (this.othersFormControl.errors && this.othersFormControl.touched) {
      if (this.othersFormControl.errors['required']) {
        return 'This field is required';
      }
    }
    return '';
  }

  getValidationState(controlName: string): ValidationState {
    const control = this.campaignForm.get(controlName);
    if (control?.touched) {
      return control.errors ? 'error' : 'success';
    }
    return null;
  }

  getControls(name: string) {
    const control = this.campaignForm.get(name);
    return control as FormControl;
  }

  getErrorMessage(controlName: string, fieldName?: string): string {
    const control = this.campaignForm.get(controlName);
    if (control?.errors && control.touched) {
      if (control.errors['required']) {
        if (fieldName) {
          return `${fieldName} is required`;
        }
        return 'This field is required';
      }
      if (control.errors['email']) {
        return 'Please enter a valid email';
      }
      if (control.errors['minlength']) {
        return `Minimum length is ${control.errors['minlength'].requiredLength}`;
      }
      if (control.errors['minlength']) {
        return `Maximum length is ${control.errors['minlength'].requiredLength}`;
      }
    }
    return '';
  }

  get submitDisabledCheck(): boolean {
    if (this.selected_role === '') return true;

    if (this.selected_role === 'others') {
      return this.campaignForm.invalid || this.othersFormControl.invalid;
    }

    return this.campaignForm.invalid;
  }

  onSubmit() {
    const campaignForm:CampaignForm = {
      name: this.getControls('name').value.toLowerCase(),
      email: this.getControls('email').value,
      phone: this.getControls('phone').value,
      age:+this.getControls('age').value,
      school_name: this.getControls('school_name').value.toLowerCase(),
      school_city: this.getControls('school_city').value.toLowerCase(),
      fav_teacher: this.getControls('fav_teacher').value.toLowerCase(),
      fav_class_memory:this.getControls('classroom_memory').value.toLowerCase(),
      role:
        this.selected_role == 'others'
          ? this.othersFormControl.value.toLowerCase()
          : this.selected_role,
      created_at: new Date(),
    };
    this.addingDoc = true;
    this.backendSrvc_
      .addCampaignForm(campaignForm)
      .then(() => {
        this.addingDoc = false;
        this.router.navigate(['/']);
      })
      .catch((error) => {
        this.addingDoc = false;
        console.log(error);
      });
  }


}
