import { CommonModule, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  AtomoInputComponent,
  ValidationState,
} from '../../../shared/reusable-components/atomo-input/atomo-input.component';
import { NumbersOnlyDirective } from '../../../shared/directives/numbers-only.directive';
import { ContactUs } from '../../../shared/models/contact_us.model';
import { BackendService } from '../../../shared/services/backend.service';
import { Router } from '@angular/router';

@Component({
  selector: 'get-in-touch-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AtomoInputComponent,
    NumbersOnlyDirective,
    NgIf
  ],
  templateUrl: './get-in-touch-details.component.html',
  styleUrl: './get-in-touch-details.component.scss',
})
export class GetInTouchDetailsComponent {
  othersFormControl: FormControl = new FormControl(
    { value: '', disabled: false },
    [Validators.required]
  );

  private backendSrvc_: BackendService = inject(BackendService);

  private router:Router = inject(Router)

  addingDoc: boolean = false;

  selected_role: string = '';

  roles: { role: string; name: string }[] = [
    {
      role: 'csr_funders',
      name: 'CSR Funder',
    },
    {
      role: 'system_integrator',
      name: 'System Integrator',
    },
    {
      role: 'ngo',
      name: 'NGOs',
    },
    {
      role: 'smart_class_provider',
      name: 'Smart Class Provider',
    },

    {
      role: 'government_body',
      name: 'Government Body',
    },
    {
      role: 'others',
      name: 'Other',
    },
  ];

  getInTouchForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone_number: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
  });

  description: string = '';

  handleCheckboxChange(role: { role: string; name: string }) {
    this.selected_role = role.role;
    console.log(this.selected_role);
    if (this.selected_role == 'others') {
      this.othersFormControl.enable();
    } else {
      this.othersFormControl.reset();
      this.othersFormControl.disable();
    }
  }

  get submitDisabledCheck(): boolean {
    if (this.selected_role === '') return true;

    if (this.selected_role === 'others') {
      return this.getInTouchForm.invalid || this.othersFormControl.invalid;
    }

    return this.getInTouchForm.invalid;
  }

  // Add these methods for others form control validation
  getOthersValidationState(): ValidationState {
    if (this.othersFormControl.touched) {
      return this.othersFormControl.errors ? 'error' : 'success';
    }
    return null;
  }

  getOthersErrorMessage(): string {
    if (this.othersFormControl.errors && this.othersFormControl.touched) {
      if (this.othersFormControl.errors['required']) {
        return 'This field is required';
      }
    }
    return '';
  }

  getValidationState(controlName: string): ValidationState {
    const control = this.getInTouchForm.get(controlName);
    if (control?.touched) {
      return control.errors ? 'error' : 'success';
    }
    return null;
  }

  getControls(name: string) {
    const control = this.getInTouchForm.get(name);
    return control as FormControl;
  }

  getErrorMessage(controlName: string, fieldName?: string): string {
    const control = this.getInTouchForm.get(controlName);
    if (control?.errors && control.touched) {
      if (control.errors['required']) {
        if (fieldName) {
          return `${fieldName} is required`;
        }
        return 'This field is required';
      }
      if (control.errors['email']) {
        return 'Please enter a valid email';
      }
      if (control.errors['minlength']) {
        return `Minimum length is ${control.errors['minlength'].requiredLength}`;
      }
      if (control.errors['minlength']) {
        return `Maximum length is ${control.errors['minlength'].requiredLength}`;
      }
    }
    return '';
  }

  onSubmit() {
    const contactUsDetails: ContactUs = {
      created_at: new Date(),
      description: this.description.toLowerCase(),
      email: this.getControls('email').value,
      role:
        this.selected_role == 'others'
          ? this.othersFormControl.value.toLowerCase()
          : this.selected_role,
      organization_name: this.getControls('name').value.toLowerCase(),
      phone: this.getControls('phone_number').value,
    };
    this.addingDoc = true;
    this.backendSrvc_
      .addDocToContactUs(contactUsDetails)
      .then(() => {
        this.addingDoc = false;
        this.router.navigate(['/'])
      })
      .catch((error) => {
        this.addingDoc = false;
        console.log(error)
      });
  }
}
