<section id="features_container"
  class="features_container px-4 md:px-14 lg:px-[108px] pt-10 lg:pt-12 flex flex-col gap-16 md:gap-0 items-start pb-20 md:pb-10 lg:pb-24">
  <p class="text-amber_100 text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 font-bold" id="math-features-head">
    Features</p>

  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24 ">
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center ">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold" id="math-feature-1"> Mathematical Expressions</p>
      <p id="math-feature-1-description" class="text-black text-hurrey-16"> A short mix of numbers, symbols, and
        operations to show a math relationship or calculation.</p>
    </div>
    <div
      class="col-span-2 md:col-span-1 flex  items-start justify-start md:items-center md:justify-center order-first md:order-last ">
      <img src="../../../../assets/images/mathematical-expression.webp" alt="mathematical_expression">
    </div>
  </div>



  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24 ">
    <div class="col-span-2 md:col-span-1 flex items-start justify-start  md:items-center  md:justify-center">
      <img src="../../../../assets/images/structured-guidence.webp" alt="structured-guidence">
    </div>
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold" id="math-feature-2"> Get Structured Guidance</p>
      <p class="text-black text-hurrey-16" id="math-feature-2-description"> Get the guidance of solving a mathematical
        query in multiple and structured
        way, and learn the concepts behind the
        query.</p>
    </div>

  </div>

  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24">
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold" id="math-feature-3"> Comprehensive Learning</p>
      <p class="text-black text-hurrey-16" id="math-feature-3-description"> Get a brief about your ongoing mathematical
        topics before attending your
        next class.</p>
    </div>

    <div
      class="col-span-2 md:col-span-1 flex items-start justify-start  md:items-center md:justify-center order-first md:order-last ">
      <img src="../../../../assets/images/comprehensive_learning.webp" alt="comprehensive_learnibg">

    </div>


  </div>

  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24 ">
    <div class="col-span-2 md:col-span-1 flex items-start justify-start md:items-center md:justify-center">
      <img src="../../../../assets/images/refine_your_skill.webp" alt="refine_your_skill">
    </div>
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold" id="math-feature-4"> Refine Your Skills</p>
      <p class="text-black text-hurrey-16" id="math-feature-4-description"> Put into practice what you've learned and
        seek support whenever you face
        difficulties.</p>
    </div>

  </div>

  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24">
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold" id="math-feature-5"> Test Your Concepts</p>
      <p class="text-black text-hurrey-16" id="math-feature-5-description">Rise through the ranks on Mathsolver's quiz
        feature, where excellence and
        competition collide for the ultimate test of
        skill!</p>
    </div>

    <div
      class="col-span-2 md:col-span-1 flex items-start justify-start  md:items-center md:justify-center order-first md:order-last ">
      <img src="../../../../assets/images/test_your_concept.webp" alt="test_your_concept">

    </div>


  </div>


</section>
