<div class="custom-input-container" [class.small]="size === 'small'" [class.large]="size === 'large'"
  [style.width]="width">
  <div class="label-container" *ngIf="label">
    <label [for]="inputId">
      {{ label }}
      <span class="required-marker" *ngIf="required">*</span>
    </label>
    <span class="helper-text" *ngIf="helperText">{{ helperText }}</span>
  </div>

  <div class="input-wrapper" [class.has-prefix]="!!prefix" [class.has-suffix]="!!suffix">
    <div class="prefix" *ngIf="prefix">{{ prefix }}</div>

    <div class="input-container">
      <input [id]="inputId" [type]="type" [placeholder]="placeholder" [value]="value" [disabled]="disabled"
        [readonly]="readonly" [attr.maxlength]="maxLength" [attr.minlength]="minLength" [attr.min]="min"
        [attr.max]="max" [attr.pattern]="pattern" [class.error-state]="validationState === 'error'"
        [class.success-state]="validationState === 'success'" (input)="onInput($event)" (blur)="onBlur()"
        (focus)="onFocus.emit($event)" [style.background-color]="backgroundColor" [style.color]="textColor" />

      <button *ngIf="type === 'password' && allowTogglePassword" type="button" class="toggle-password"
        (click)="togglePasswordVisibility()">
        {{ showPassword ? '👁️' : '👁️‍🗨️' }}
      </button>
    </div>

    <div class="suffix" *ngIf="suffix">{{ suffix }}</div>
  </div>

  <div class="validation-container">
    <div class="character-count" *ngIf="showCharacterCount && maxLength">
      {{ value!!.length || 0 }}/{{ maxLength }}
    </div>

    <div class="validation-message" *ngIf="showValidationMessage" [class.error]="validationState == 'error'">
      {{ validationMessage }}
    </div>
  </div>
</div>
