import { NgClass,} from '@angular/common';
import { Component } from '@angular/core';
import {
  fadeInLeftOnEnterAnimation,
  fadeInRightOnEnterAnimation,
  fadeOutLeftOnLeaveAnimation,
  fadeOutRightOnLeaveAnimation,
} from 'angular-animations';
import { IconsModule } from '../../icons/icons.module';

@Component({
  selector: 'atomos-usp',
  standalone: true,
  imports: [ NgClass, IconsModule],
  templateUrl: './atomos-usp.component.html',
  styleUrl: './atomos-usp.component.scss',
  animations: [
    fadeInLeftOnEnterAnimation(),
    fadeInRightOnEnterAnimation(),
    fadeOutLeftOnLeaveAnimation(),
    fadeOutRightOnLeaveAnimation(),
  ],
})
export class AtomosUspComponent {
  bgArray: string[] = ['#FFB600', '#307FE2', '#000000', '#6b4eff'];

  selectedIndex: number = 0;

  direction: 'ltr' | 'rtl' = 'ltr';

  atomo_usps: { title: string; description: string; bgImage: string }[] = [

    {
      title: 'Education Without Boundaries',
      description:
        'Break barriers with Atomo—bringing quality education to everyone, anywhere, anytime.',
      bgImage: '../../../../assets/images/banner_5_v1-gray.webp',
    },
    {
      title: 'Individualized Learning, Powered by AI',
      description:
        'Atomo adapts to every student, teacher, and subject—tailored lessons and instant feedback.',
      bgImage: '../../../../assets/images/banner_3.webp',
    },
    {
      title: 'Education That Lasts Forever',
      description:
        'Education is unbreakable, and Atomo makes it last—strong, reliable and built for success.',
      bgImage: '../../../../assets/images/banner_2.webp',
    },

    {
      title: 'Connect, Collaborate, Succeed',
      description:
        'Atomo connects students to collaborate and achieve goals—all in one simple platform.',
      bgImage: '../../../../assets/images/banner_4.webp',
    },
    {
      title: 'Empowering Your Learning Journey',
      description:
        'All-in-one AI-powered classroom—learn, grow, and succeed with Atomo, effortlessly.',
      bgImage: '../../../../assets/images/banner_1.webp',
    },
    {
      title: 'Befriend AI. Enhance learning and accessibility',
      description:
        'AI aids knowledge acquisition and deepens learning, transforming the future of education. Be Future ready with ConCat',
      bgImage: '../../../../assets/images/banner_6.webp',
    },

  ];

  navigateBetweenSlides(dir: 1 | -1) {
    const newIndex = this.selectedIndex + dir;
    if (newIndex >= 0 && newIndex < this.atomo_usps.length) {
      this.direction = dir === 1 ? 'rtl' : 'ltr';
      this.selectedIndex = newIndex;
    }
  }

  returnBg() {
    return `url(${this.atomo_usps[this.selectedIndex].bgImage})`;
  }
}
