import { NgClass, NgFor } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  inject,
} from '@angular/core';
import { ImplementationSlide } from '../../../shared/models/implementation-slide.model';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  rubberBandAnimation,
} from 'angular-animations';

@Component({
  selector: 'how-do-we-implement',
  standalone: true,
  imports: [NgClass, NgFor],
  templateUrl: './how-do-we-implement.component.html',
  styleUrl: './how-do-we-implement.component.scss',
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
    rubberBandAnimation(),
  ],
})
export class HowDoWeImplementComponent {
  private el: ElementRef = inject(ElementRef);

  implementationSlidesContent: ImplementationSlide[] = [
    {
      head: 'Identification of Resource',
      description:
        "The solution is tailormade as per the resource availability",
      bgColor: '#6b4eff',
    },
    {
      head: 'Hardware Deployment',
      description:
        'Our endeavor involves not only building a robust hybrid classroom infrastructure but also ensuring its seamless integration within educational systems.',
      bgColor: '#307fe2',
    },
    {
      head: 'Digitalization',
      description:
        'By forming a comprehensive digital footprint, we aim to leave a lasting impact on the educational landscape, driving innovation and progress.',
      bgColor: '#c51c6b',
    },
    {
      head: 'Capability Building',
      description:
        'Through a combination of online and offline training sessions, we empower educators and students alike, fostering skill development and growth.',
      bgColor: '#dd9f04',
    },
    {
      head: 'Go Live!',
      description:
        'After meticulous preparation, our classrooms are now operational and fully utilized, serving as dynamic hubs for learning and collaboration.',
      bgColor: '#969696',
    },
  ];

  public currentSlide = 0;
  private actualChildheight!: number;
  private isScrolledManually: boolean = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    if (!this.isScrolledManually) {
      const scrollableWrapper =
        this.el.nativeElement.querySelector('.scroll_wrapper');

      const wrapperTop = scrollableWrapper.getBoundingClientRect().top;
      // check if it reaches top
      if (wrapperTop == 0) {
        const parent = this.el.nativeElement.querySelector(
          '.scrollable-container'
        );
        const parentY = parent.getBoundingClientRect().y * -1;
        const totalParentHeight = parent.getBoundingClientRect().height;
        this.actualChildheight =
          totalParentHeight / this.implementationSlidesContent.length;
        this.currentSlide = Math.floor(parentY / this.actualChildheight);
      }
    }
  }

  moveToSlide(index: number) {
    if (index == this.currentSlide) {
      return;
    }
    this.isScrolledManually = true;
    const difference = this.currentSlide - index;
    this.currentSlide = index;
    window.scrollBy({
      top: difference * this.actualChildheight * -1,
      behavior: 'smooth',
    });
    setTimeout(() => {
      this.isScrolledManually = false;
    }, 1000);
  }
}
