<div class=" h-screen join_us_container flex  px-5 lg:px-[108px] md:px-14   ">
  <div class="grid grid-cols-2 gap-8 lg:gap-28 md:gap-20">
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 items-start justify-center ">
      <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48 text-white">Get in touch</p>
      <p class="text-hurrey-16 text-white"> We’re here to connect, collaborate, and help you shape the future of
        education with Atomo.</p>
    </div>
    <div class="col-span-2 md:col-span-1 flex items-end justify-center ">
      <img src="../../../../assets/images/join_us_girl.webp" alt="student" class="images_container">
    </div>
  </div>
</div>
