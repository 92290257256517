import { Routes } from '@angular/router';
import { AtomosLayoutComponent } from './core/atomos-layout/atomos-layout.component';
import { LandingComponent } from './modules/landing/landing/landing.component';
import { UseCasesComponent } from './modules/inner-pages/use-cases/use-cases.component';
import { PricingComponent } from './modules/inner-pages/pricing/pricing.component';
import { AtomoProductsComponent } from './modules/inner-pages/atomo-products/atomo-products.component';
import { CompanyComponent } from './modules/inner-pages/company/company.component';
import { JoinUsComponent } from './modules/join-us/join-us/join-us.component';
import { CheckEligibilityComponent } from './modules/check-eligiblity/check-eligibility/check-eligibility.component';
import { MathSolverComponent } from './modules/inner-pages/math-solver/math-solver.component';
import { PhysicsSolverComponent } from './modules/inner-pages/physics-solver/physics-solver.component';
import { LanguageLearnerComponent } from './modules/inner-pages/language-learner/language-learner.component';
import { HurreyScopeComponent } from './modules/inner-pages/hurrey-scope/hurrey-scope.component';
import { BlogsComponent } from './modules/blogs/blogs/blogs.component';
import { BlogComponent } from './modules/blogs/blog/blog.component';
import { TermsAndConditionsComponent } from './modules/legal/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './modules/legal/privacy-policy/privacy-policy.component';
import { metaTags } from './shared/const/meta';
import { ResellerComponent } from './modules/reseller/reseller/reseller.component';
import { CampaignLandingComponent } from './modules/campaign-landing/campaign-landing/campaign-landing.component';

export const routes: Routes = [
  // {'',pathMatch:'full',redirectTo:AtomosLayoutComponent},
  {
    path: '',
    component: AtomosLayoutComponent,
    children: [
      { path: '', component: LandingComponent, data: metaTags.landing },
      {
        path: 'use-cases',
        component: UseCasesComponent,
        data: metaTags.useCases,
      },
      // { path: 'pricing', component: PricingComponent },
      {
        path: 'products',
        component: AtomoProductsComponent,
        data: metaTags.products,
      },
      { path: 'company', component: CompanyComponent, data: metaTags.company },
      { path: 'join-us', component: JoinUsComponent },
      // { path: 'check-eligiblity', component: CheckEligibilityComponent },
      {
        path: 'math-solver',
        component: MathSolverComponent,
        data: metaTags.mathSolver,
      },
      {
        path: 'physics-solver',
        component: PhysicsSolverComponent,
        data: metaTags.physicsSolver,
      },
      {
        path: 'language-learner',
        component: LanguageLearnerComponent,
        data: metaTags.languageLearner,
      },
      {
        path: 'hurrey-scope',
        component: HurreyScopeComponent,
        data: metaTags.hurreyScope,
      },
      { path: 'blogs', component: BlogsComponent, data: metaTags.blogs },
      { path: 'blogs/:slug', component: BlogComponent },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        data: metaTags.privacyPolicy,
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
        data: metaTags.termsAndConditions,
      },
      {
        path: 'reseller',
        component: ResellerComponent,
        data: metaTags.reseller,
      },

    ],
  },
  { path: 'campaign', component: CampaignLandingComponent,data: metaTags.campaign, },
  { path: '**', redirectTo: '' },
];
