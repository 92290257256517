import { NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

export type InputType =
  | 'text'
  | 'password'
  | 'email'
  | 'number'
  | 'tel'
  | 'url'
  | 'date'
  | 'time'
  | 'search';
export type InputSize = 'small' | 'medium' | 'large';
export type ValidationState = 'success' | 'error' | null;

@Component({
  selector: 'atomo-input',
  standalone: true,
  imports: [NgIf],
  templateUrl: './atomo-input.component.html',
  styleUrl: './atomo-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AtomoInputComponent),
      multi: true,
    },
  ],
})
export class AtomoInputComponent implements ControlValueAccessor {
  @Input() type: InputType = 'text';
  @Input() label?: string;
  @Input() required: boolean = false;
  @Input() placeholder: string = '';
  @Input() readonly: boolean = false;
  @Input() helperText?: string;
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() size: InputSize = 'medium';
  @Input() width?: string;
  @Input() maxLength?: number;
  @Input() minLength?: number;
  @Input() min?: number | string;
  @Input() max?: number | string;
  @Input() pattern?: string;
  @Input() validationState: ValidationState =null;
  @Input() validationMessage?: string;
  @Input() showCharacterCount: boolean = false;
  @Input() allowTogglePassword: boolean = true;
  @Input() backgroundColor?: string;
  @Input() textColor?: string;
  @Input() customValidators?: ((value: any) => ValidationErrors | null)[];
  @Input() fieldName?: string;

  @Output() onFocus = new EventEmitter<FocusEvent>();
  @Output() onValueChange = new EventEmitter<string>();

  // Component state
  value: string = '';
  disabled: boolean = false;
  touched: boolean = false;
  showPassword: boolean = false;
  inputId: string = `custom-input-${Math.random().toString(36).substr(2, 9)}`;

  // ControlValueAccessor methods
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};


  get showValidationMessage(): boolean {
    return this.touched && !!this.validationMessage && this.validationState === 'error';
  }

  writeValue(value: string): void {
    this.value = value || '';
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Validator implementation
  validate(control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};

    // Required validation
    if (this.required && !control.value) {
      errors['required'] = true;
    }

    // Min length validation
    if (this.minLength && control.value?.length < this.minLength) {
      errors['minlength'] = {
        requiredLength: this.minLength,
        actualLength: control.value?.length,
      };
    }

    // Max length validation
    if (this.maxLength && control.value?.length > this.maxLength) {
      errors['maxlength'] = {
        requiredLength: this.maxLength,
        actualLength: control.value?.length,
      };
    }

    // Pattern validation
    if (this.pattern && control.value) {
      const regex = new RegExp(this.pattern);
      if (!regex.test(control.value)) {
        errors['pattern'] = {
          requiredPattern: this.pattern,
          actualValue: control.value,
        };
      }
    }

    // Custom validators
    if (this.customValidators) {
      this.customValidators.forEach((validator) => {
        const validationError = validator(control.value);
        if (validationError) {
          Object.assign(errors, validationError);
        }
      });
    }

    return Object.keys(errors).length ? errors : null;
  }

  // Event handlers
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value;
    this.onChange(this.value);
    this.onValueChange.emit(this.value);
  }

  onBlur(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
    this.type = this.showPassword ? 'text' : 'password';
  }
}
