import { Component } from '@angular/core';
import {
  slideInRightOnEnterAnimation,
  slideInUpOnEnterAnimation,
} from 'angular-animations';
import { NgFor } from '@angular/common';
import { atomoEducation } from '../../../shared/models/atomo-education.model';

@Component({
  selector: 'campaign-usp',
  standalone: true,
  imports: [NgFor],
  templateUrl: './campaign-usp.component.html',
  styleUrl: './campaign-usp.component.scss',
  animations: [
    slideInUpOnEnterAnimation({ anchor: 'slideInOut' }),
    slideInRightOnEnterAnimation({ anchor: 'slideInRight' }),
  ],
})
export class CampaignUspComponent {
  features: atomoEducation[] = [
    {
      img: '../../../../assets/images/interactive_learning_purple.svg',
      title: 'Interactive',
      description: ' Learning that adapts to you',
    },
    {
      img: '../../../../assets/images/accessible.svg',
      title: 'Accessible',
      description: 'Reaching every student, anywhere',
    },
    {
      img: '../../../../assets/images/target_goal.svg',
      title: 'Sustainable',
      description: 'Future-forward, just like our goals',
    },
  ];
}
