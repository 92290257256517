<campaign-header></campaign-header>
<campaign-usp></campaign-usp>
<campaign-form></campaign-form>
<div class="campaign_footer bg-purple_100 p-6 md:p-8 flex flex-col-reverse gap-3  md:flex-row md:justify-between md:items-center">
  <p class="text-hurrey-12 text-white">Copyright &#169; 2024-2025, Apeiros Eduventures Pvt. Ltd.</p>
  <div class="flex gap-2 items-center">
    <a href="https://www.linkedin.com/company/atomoclass" target="_blank" rel="noopener noreferrer nofollow"
    title="Visit Atomo's LinkedIn Page">
    <img src="../../../../assets/icons/linkedIn_v2.svg" alt="linkedIn" width="16" height="16">
  </a>
  <a href="https://www.youtube.com/channel/UC8vP8aLgjJA3D67Wsl1_Z4g" target="_blank" rel="noopener noreferrer nofollow"
    title="Visit Atomo's YouTube channel">
    <img src="../../../../assets/icons/youtube.svg" alt="youtube" width="16" height="16">
  </a>
  <a href="https://www.facebook.com/profile.php?id=61569609287836" target="_blank"
    rel="noopener noreferrer nofollow" title="Visit Atomo's Facebook Page">
    <img src="../../../../assets/icons/fb_logo.svg" alt="facebook" width="16" height="16">
  </a>
  <a href="https://www.instagram.com/theatomoai/" target="_blank" rel="noopener noreferrer nofollow"
    title="Visit Atomo's Instagram Page">
    <img src="../../../../assets/icons/insta_logo.svg" alt="instagram" width="16" height="16">
  </a>
  <a href="https://x.com/theatomoai" target="_blank" rel="noopener noreferrer nofollow"
    title="Visit Atomo's Page on X">
    <img src="../../../../assets/icons/x-logo.svg" alt="X" width="16" height="16">
  </a>
  </div>
</div>


