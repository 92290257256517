<section id="physics_solver_features"
  class="features_container flex flex-col items-center justify-center px-4 md:px-14 lg:px-[108px]  pt-10 lg:pt-12 pb-20 md:pb-10 lg:pb-24 ">
  <p class="text-hurrey-24 text-purple_100 font-bold mb-3">Features</p>
  <p class="text-hurrey-24 lg:text-hurrey-36 text-black mb-14 font-bold text-center"> Discover what sets our <br
      class="block md:hidden">
    Physics Solver apart
  </p>
  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24 ">
    <div class="col-span-2 md:col-span-1 flex  items-start justify-start md:items-center md:justify-center ">
      <img src="../../../../assets/images/comprehensive_learning_physics_solver.webp" alt="comprehensive_learning">
    </div>
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center mb-6 md:mb-0">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold" id="physics_solver_feature_1"> Comprehensive
        Learning</p>
      <p class="text-black text-hurrey-16" id="physics_solver_feature_1_description"> Review the key physics concepts
        before your upcoming quiz with a concise synopsis.</p>
    </div>
  </div>


  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24 ">
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center ">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold" id="physics_solver_feature_1"> Get Structured
        Guidance</p>
      <p class="text-black text-hurrey-16" id="physics_solver_feature_2_description"> Receive guidance on solving a
        physics problem with a detailed explanation and additional related concepts.</p>
    </div>
    <div
      class="col-span-2 md:col-span-1 flex  items-start justify-start md:items-center md:justify-center order-first md:order-last mb-6 md:mb-0 ">
      <img src="../../../../assets/images/get_structured_guidance.webp" alt="get_structured_guidance">
    </div>
  </div>

  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24 ">
    <div class="col-span-2 md:col-span-1 flex items-start justify-start md:items-center md:justify-center ">
      <img src="../../../../assets/images/refine_your_skills_physics_solver.webp" alt="refine_your_skills">
    </div>
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center mb-6 md:mb-0">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold" id="physics_solver_feature_3">Refine Your Skills
      </p>
      <p class="text-black text-hurrey-16" id="physics_solver_feature_3_description">Improve your ranking on
        Physics Solver's quizzes, where competition enhances learning for everyone.</p>
    </div>

  </div>

  <div class="grid grid-cols-2 gap-6 md:gap-14 lg:gap-24 ">
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 lg:gap-6 items-start justify-center ">
      <p class="text-black text-hurrey-24 lg:text-hurrey-28 font-bold" id="physics_solver_feature_4">Virtual Lab</p>
      <p class="text-black text-hurrey-16" id="physics_solver_feature_4_description">Close resource gaps, collaborate,
        and enhance physics education with hands-on learning at our center.</p>
    </div>
    <div
      class="col-span-2 md:col-span-1 flex items-start justify-start md:items-center md:justify-center order-first md:order-last mb-6 md:mb-0 ">
      <img src="../../../../assets/images/virtual_lab.webp" alt="virtual_lab">
    </div>
  </div>


</section>
