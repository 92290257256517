<section class="bg-rose_100 " id="atomo-genesis">
  <div class="grid grid-cols-2 py-14 lg:py-24 px-4 md:px-10 lg:px-[108px] gap-14 md:gap-10  lg:gap-14 ">
    <div [@slideInRight] class="col-span-2 flex flex-col gap-6 ">
      <p class="text-white text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36" id="atomo-genesis_head"> Our Journey</p>
      <p class="text-white text-hurrey-16 lg:max-w-[80%]" id="atomo-genesis_description"> In 2016, Gap began innovating
        content delivery, incorporating
        in 2019,
        deploying AI modules, and achieving a $5 million
        ARR by 2023. Meanwhile, Atomo AI, founded in 2017, focuses on personalized information delivery. After six
        years of
        advancement, it stands as a leader in adaptive AI models, emphasizing openness and collaboration in the deep
        tech
        ecosystem.</p>
    </div>


    <div [@slideInLeft] class="col-span-2">

      <div class="hidden md:block">

        <div class="description">

          <div class="flex flex-col gap-3 ">
            <p class="text-hurrey-16 text-white font-normal lg:font-bold" id="atomo_gaps_identified">Gap identified</p>
            <ul class="flex flex-col gap-2">
              <li class="text-hurrey-12" id="atomo_gap_1"> Content delivery and Consumption</li>
              <li class="text-hurrey-12" id="atomo_gap_2"> Initial R&D to address the horizon</li>

            </ul>

          </div>

          <div class="flex flex-col gap-3">
            <p class="text-white text-hurrey-16  font-normal lg:font-bold" id="atomo_incorporations_head"> Incorporated
            </p>
            <ul class="flex flex-col gap-2" id="atomo_incorporations">
              <li class="text-hurrey-12 " id="creating_ai_modules"> Creating AI modules</li>
              <li class="text-hurrey-12" id="patents_applied"> Patent(s) applied</li>
              <li class="text-hurrey-12" id="deploy_usecases">Deploying different use cases</li>
            </ul>
          </div>

          <div class="flex flex-col gap-3">
            <p class="text-hurrey-16 text-white font-normal lg:font-bold" id="alpha_launch"> Alpha launch</p>
            <ul class="flex flex-col gap-2">
              <li class="text-hurrey-12" id="went_commercial"> Went commercial</li>
              <li class="text-hurrey-12" id="intro_contextual_ai"> Introduced Contextual AI</li>
              <li class="text-hurrey-12" id="current_revenue"> Current ARR $5mn</li>
              <li class="text-hurrey-12" id="training_models">Training Models </li>
            </ul>

          </div>

          <div class="flex flex-col gap-2">
            <p class="text-white text-hurrey-16 font-normal lg:font-bold" id="planning"> Planning</p>
            <p class="text-white text-hurrey-16" id="beta_launch"> For full beta launch</p>

          </div>


        </div>


        <div class="step-progress" id="company-journey">
          <div class="step-line"></div>
          <div class="step-indicator" *ngFor="let step of [2016, 2019, 2023, 2024]">
            <div class="step-circle" [class.active]="currentStep >= step" [attr.data-year]="step"></div>
            <div class="step-label text-hurrey-48">{{ step }}</div>
          </div>
        </div>


      </div>


      <!-- this is for mobile  -->

      <div class=" block md:hidden">
        <div class="col-span-2 flex gap-6">

          <div class="mobile_step_value">
            <p class="text-white text-hurrey-24 ">2016</p>
            <p class="text-white text-hurrey-24"> 2019</p>
            <p class="text-white text-hurrey-24"> 2023</p>
            <p class="text-white text-hurrey-24">2024</p>

          </div>


          <div class="step-progress_mobile">
            <div class="step-line_mobile"></div>
            <div class="step-indicator_mobile" *ngFor="let step of [2016, 2019, 2023, 2024]">
              <div class="step-circle_mobile" [class.active]="currentStep >= step" [attr.data-year]="step"></div>
            </div>
          </div>

          <div class="mobile_description">
            <div class="flex flex-col gap-3 ">
              <p class="text-hurrey-16 text-white" id="atomo_gaps_identified">Gap identified</p>
              <ul id="atomo_gaps_identified_list">
                <li id="atomo_gaps_1"> Content delivery and Consumption</li>
                <li id="atomo_gaps_2"> Initial R&D to address the horizon</li>

              </ul>

            </div>
            <div class="flex flex-col gap-3">
              <p class="text-white text-hurrey-16" id="atomo_incorporations"> Incorporated</p>
              <ul id="atomo_incorporations_list">
                <li id="creating_ai_modules"> Creating AI modules</li>
                <li id="patents_identified"> Patent(s) applied</li>
                <li id="deploy_use_cases">Deploying different use cases</li>
              </ul>
            </div>

            <div class="flex flex-col gap-3">
              <p class="text-hurrey-16 text-white" id="alpha_launch"> Alpha launch</p>
              <ul id="alpha_launch_list">
                <li id="went_commercial"> Went commercial</li>
                <li id="contextual_ai"> Introduced Contextual AI</li>
                <li id="current_revenue"> Current ARR $5mn</li>
                <li id="training_models">Training Models </li>
              </ul>

            </div>
            <div class="flex flex-col gap-2">
              <p class="text-white text-hurrey-16" id="atomo-planning"> Planning</p>
              <p class="text-white text-hurrey-16" id="beta_launch"> For full beta launch</p>
            </div>


          </div>

        </div>


      </div>



    </div>



    <!-- this is for mobile  -->





  </div>

</section>
