import { Component } from '@angular/core';
import { fadeInLeftOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'campaign-header',
  standalone: true,
  imports: [],
  templateUrl: './campaign-header.component.html',
  styleUrl: './campaign-header.component.scss',
  animations:[fadeInLeftOnEnterAnimation()]
})
export class CampaignHeaderComponent {

}
