import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { slideInUpOnEnterAnimation } from 'angular-animations';
import { Subscription, interval, take } from 'rxjs';

@Component({
  selector: 'one-repository-unified-exp',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './one-repository-unified-exp.component.html',
  styleUrl: './one-repository-unified-exp.component.scss',
  animations: [slideInUpOnEnterAnimation({ anchor: 'slideInOut' })],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneRepositoryUnifiedExpComponent implements OnInit, OnDestroy {
  @ViewChild('repository') repo!: ElementRef;

  @HostListener('window:scroll') onScroll() {
    const repoContainer = this.repo.nativeElement as HTMLDivElement;

    let repoRect = repoContainer.getBoundingClientRect();

    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );

    if (!(repoRect.bottom < 0 || repoRect.top - viewHeight >= 0)) {
      this.mouseEnter();
    }
  }

  items = [
    {
      title: 'ConCat',
      index: 0,
    },
    {
      title: 'Math Solver',
      index: 1,
    },
    {
      title: 'Physics Solver',
      index: 2,
    },
    {
      title: 'Language Learner',
      index: 3,
    },
    {
      title: 'Odyo',
      index: 4,
    },
  ];

  currentIndex = 0;

  intervalSub: Subscription = new Subscription();

  ngOnInit() { }

  mouseEnter() {
    if (this.intervalSub) this.intervalSub.unsubscribe();
    this.intervalSub = interval(2000).subscribe({
      next: () => {
        this.currentIndex = (this.currentIndex + 1) % this.items.length;
      },
    });
  }

  moveToCurrentIndex(index: number) {
    this.currentIndex = index;
  }

  ngOnDestroy(): void {
    if (this.intervalSub) this.intervalSub.unsubscribe();
  }
}
