import {  NgIf, NgOptimizedImage } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { ClickOutsideDirective } from '../../shared/directives/click-outside.directive';
import {
  expandOnEnterAnimation,
  collapseOnLeaveAnimation,
  fadeInExpandOnEnterAnimation,
  fadeOutCollapseOnLeaveAnimation,
} from 'angular-animations';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'atomos-header',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgIf,
    ClickOutsideDirective,
    RouterLink,
  ],
  templateUrl: './atomos-header.component.html',
  styleUrl: './atomos-header.component.scss',
  animations: [
    fadeInExpandOnEnterAnimation({ duration: 400 }),
    fadeOutCollapseOnLeaveAnimation({ duration: 400 }),
    expandOnEnterAnimation({ duration: 400 }),
    collapseOnLeaveAnimation({ duration: 400 }),
  ],
})
export class AtomosHeaderComponent {
  selectedItem: string = '';

  selectedItemMobile: string = '';

  isListOpen: boolean = false;

  isDropDownOpen: boolean = false;

  isDropdownMobileOpened: boolean = false;

  playStoreLink: string =
    'https://play.google.com/store/apps/details?id=com.hurrey.superapp';

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (screen.width > 767) {
      this.selectedItemMobile = '';
      this.isListOpen = false;
      this.isDropdownMobileOpened = false;
    } else {
    }
  }

  onHeaderItemClick(type: string) {
    console.log(this.isDropDownOpen, this.selectedItem, type);
    if (this.selectedItem === type) {
      if (this.isDropDownOpen) {
        this.selectedItem = '';
        this.isDropDownOpen = false;
      } else {
        this.selectedItem = type;
        this.isDropDownOpen = true;
      }
    } else {
      this.selectedItem = type;
      this.isDropDownOpen = true;
    }
  }

  onItemClickMobile(type: string) {
    if (this.selectedItemMobile === type) {
      if (this.isListOpen) {
        this.selectedItemMobile = '';
        this.isListOpen = false;
      } else {
        this.selectedItemMobile = type;
        this.isListOpen = true;
      }
    } else {
      this.selectedItemMobile = type;
      this.isListOpen = true;
    }
    this.isDropdownMobileOpened = false;
  }

  mobileDropDownToggle() {
    this.isDropdownMobileOpened = !this.isDropdownMobileOpened;

    if (!this.isDropdownMobileOpened) {
      this.isListOpen = false;
      this.selectedItemMobile = '';
    }
  }

  onOutSideClick(event: any) {
    this.isDropDownOpen = false;
  }
}
