<section id="atomo-about-container"
  class="h-screen atomo_about_container flex  items-center px-4 md:px-14 lg:px-[108px]">

  <div [@slideInOut] class="flex flex-col gap-4 md:gap-6">
    <p class="text-white text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48"> About Atomo</p>
    <p class="text-white text-hurrey-16 break-words md:w-[70%]"> Atomo, an AI-powered education software in India,  leading educational AI solution that
      embodies personalized learning experiences. Named after the Latin word &quot;Atomo,&quot; meaning
      &quot;individual,&quot; it signifies company's dedication to understanding each learner. Through tailored
      recommendations, continuous feedback, and assessments, Atomo customizes education for every
      student. This innovative approach transforms learning, empowering students to follow their unique
      paths to knowledge and growth.
    </p>


  </div>

</section>
